@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.ecom {
    width: 100%;
    position: relative;
}

.card {
    position: relative;
    margin-bottom: 16px;   
}

.card input {
    font-family: 'Courier Prime', monospace;
}

.card .front,
.card .back {
    position: absolute;
    width: 79%;
    border-radius: 20px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px 0px #00000014;
}

.card .front {
    padding: 16px;
    color: #757575;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.card .back {
    color: #757575;
    z-index: 1;
    right: 0;
    transform: translateY(30px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 16px;
    font-weight: 400;
}

.logo {
    display: flex;
    align-items: center;
    gap: 6px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.column {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
}

.rowBlock {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.row .small {
    width: 30%;
}

.row .large {
    width: 65%;
}

.row .small input {
    text-align: center;
}

.column label,
.row label,
.cvv label {
    font-size: 12px;
    color: #2B2B2A;
}

.column input,
.row input,
.cvv input {
    color: #2B2B2A;
    padding: 4px 12px;
    border: 1px solid #A0A0A0;
    border-radius: 16px;
    font-weight: 400;
    font-size: 16px;
}

.large input {
    font-size: 14px;
    padding: 5px 12px;
}

/* .large input::placeholder {
    font-size: 20px;
} */

.column input::placeholder {
    letter-spacing: 0.6px;
}

label {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
}

input {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.9);
    font-size: 16px;
    color: #333;
}

.column .cardNumber {
    letter-spacing: 2px;
}

input.invalid {
    border: 1px solid red;
}

/* Магнитная полоса на задней стороне */
.stripe {
    width: 100%;
    height: 40px;
    background: #AAAAAA;
    margin-top: 40px;
}

.cvvBlock {
    width: 100%;
    display: flex;
    /* align-items: flex-end; */
    justify-content: flex-end;
}

.cvv {
    width: 60px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
    margin-bottom: 16px;
    margin-right: 16px;
}

.cvv label {
    width: 100%;
    text-align: left;
}

.cvv input {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
}