.loadingLoop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.loadingLoop svg {
    animation: rotateLoop 1.5s linear infinite;
}