.fakeMenu {
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: space-between;
}

.fakeMenuBlock {
    background: linear-gradient(222.4deg, #FF7700 6.42%, #E10CFF 93.77%);
    border-radius: 16px;
    padding: 8px 4px;
    position: relative;
    /* z-index: 1; */
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    isolation: isolate;
}

.fakeMenuText {
    font-size: 10px;
    font-weight: 600;
    line-height: 9.2px;
    color: #2B2B2A;
    text-align: center;
}

.fakeMenuBlock::before {
    content: '';
    position: absolute;
    background: #fff;
    z-index: -1;
    width: calc(100% - 3px);
    height: calc(100% - 3px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    
}

.fakeMenuBlockCountry {
    background: linear-gradient(222.4deg, #FF7700 6.42%, #E10CFF 93.77%);
    border-radius: 16px;
    padding: 8px 0px;
    position: relative;
    /* z-index: 1; */
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    isolation: isolate;
}

.fakeMenuBlockCountry::before {
    content: '';
    position: absolute;
    background: #fff;
    z-index: -1;
    width: calc(100% - 3px);
    height: calc(100% - 3px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    
}

.fakeMenuNull {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 8px 12px 8px 12px;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid #A0A0A0;
    opacity: 0.8px;

}

.fakeMenuLineNull {
    width: 23px;
    height: 6px;
    border-radius: 16px;
    background: #A0A0A099;
}