p,
h2,
h3 {
    margin: 0;
}

.payContainer {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 30px;
    padding: 24px;
    gap: 24px;
}

.topVertical {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.payContainerTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payForSPB {
    display: flex;
    gap: 12px;
}

.payForSPB h2 {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
}

.payForSPB .SBPico {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19px;
    height: 22px;
    border-radius: 50%;
    background: #1D1347;
    padding-left: 3px;
    margin-top: 5px;
}

.backButton {
    background: none;
    border: none;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.list p {
    font-weight: 400;
    font-size: 12px;
    line-height: 11.5px;
    color: #757575;
}


.copy:hover {
    
}


.requisite {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.phoneNumber,
.bankName,
.amountPay,
.recieverName {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #A0A0A0;
    padding-bottom: 8px;
}

.leftBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.leftBlock .ico {
    width: 34px;
    height: 34px;
    border: 1px solid #A0A0A0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ico .realIco {
    width: 18px;
    height: 18px;
    border-radius: 50%;
}

.requisiteInfo {
    display: flex;
    flex-direction: column;
}

.requisiteInfo .title {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}

.requisiteInfo .text {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
}