.copyWrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    max-width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    z-index: 10;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.7s ease, transform 1.5s ease;
    pointer-events: none;
}

.copyWrapper.show {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

.copyWrapper.hide {
    opacity: 0;
    transform: translateY(-20px);
    pointer-events: none;
}

.copyBlock {
    display: flex;
    gap: 16px;
    width: 100%;
    background: #F6F6FA;
    border-radius: 30px;
    box-shadow: 0px 4px 12px 0px #00000014;
    padding: 24px;
    color: #757575;
}