.success {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap: 16px;
    max-width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
}

.successText {
    background: #FFFFFF;
    border-radius: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-shadow: 0px 4px 12px 0px #00000014;
    padding: 24px;
    box-sizing: border-box;
}

.successText h2 {
    padding: 0;
    margin: 0;
    font-size: 24px;
    font-weight: 600;
}

.successText p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: #757575;
}

.successDone {
    display: flex;
    justify-content: center;
}

.successReturn {
    display: flex;
    margin-bottom: 60px;
}

.successReturn p {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    color: #A8A8A8;
}

.successButton {
    display: flex;
    width: 100%;
}

.successButton button {
    width: 100%;
    background: none;
    color: #FFFFFF;
    border: none;
    outline: none;
    background: linear-gradient(222.4deg, #FF7700 6.42%, #E10CFF 93.77%);
    padding: 16px;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 500;
    transition: all 150ms ease-in-out;
}

.successButton button:hover {
    color: #ffffff;
    background: #FF7700;
    box-shadow: 0px 4px 12px 0px #00000040;
}

.successFile {
    display: flex;
    width: 100%;
}

.successFile input {
    visibility: hidden;
    position: absolute; 
    left: -9999px;
}

.successFile label {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 16px 22px;
    border: 1px solid #CFCFCF;
    border-radius: 20px;
    color: #949494;
    transition: all 2s linear;
}

.successFile label:hover svg defs linearGradient stop:first-child{
    stop-color: #9BCCF9;
}

.successFile label:hover svg defs linearGradient stop:last-child{
    stop-color: #0088FF;
}

/* @media screen and (max-width: 1000px) {
    .success {
        margin-left: 24px;
        margin-right: 24px;
    }

    .successText {
        gap: 25px;
        margin: 30px 35px 0 35px;
    }

    .successText h2 {
        font-size: 24px;
    }

    .successText p {
        font-size: 16px;
    }

    .successReturn p {
        font-size: 14px;
    }

} */