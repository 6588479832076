.payment {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin-top: 30px;
}

.code {
    background: #FFFFFF;
    border-radius: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    box-shadow: 0px 4px 12px 0px #00000014;
    padding: 24px;
    box-sizing: border-box;
}

.codeInput {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 6px;
    
}

.codeInput input {
    display: flex;
    justify-content: center;
    text-align: center;
    border: 1px solid #A0A0A0;
    background: #F6F6FA;
    width: 100%;
    padding: 5px 25px;
    border-radius: 15px;
    font-size: 48px;
    font-family: Manrope;
    line-height: 11px;
}