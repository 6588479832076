.bank {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: #F6F6FA;
    border: 1px solid #A0A0A0;
    padding: 16px;
    border-radius: 16px;
}

.bank p {
    padding: 0;
    margin: 0;
}

.bank .chooseBank {
    color: #5B6B8C;
    font-weight: 600;
}

.choose {
    display: flex;
    flex-direction: row;
    gap: 7px;
    max-width: 100%;
    overflow: hidden;
}

.emptyBank,
.ourBank {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: fit-content;
    align-items: start;
    border: 1px solid #A0A0A0;
    border-radius: 20px;
    padding: 8px 12px;
    position: relative;
    z-index: 2;
}

.ourBank {
    background: linear-gradient(222.4deg, #FF7700 6.42%, #E10CFF 93.77%);
    border: none;
    z-index: 2;
}

.ourBank::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    background: #FFFFFF;
    border-radius: 18px;
    z-index: -1;

}

.ourBank .round {
    width: 36px;
    height: 36px;
    border-radius: 50%;
}

.ourBank .text {
    width: 76px;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #2B2B2A;
    font-weight: 600;
}

.emptyBank .round {
    width: 36px;
    height: 36px;
    background: #A0A0A099;
    border-radius: 50%;
}

.emptyBank .text {
    width: 70px;
    height: 14px;
    background: #A0A0A099;
    border-radius: 16px;
}

.emptyBank:nth-child(1) {
    opacity: 1;
}

.emptyBank:nth-child(2) {
    opacity: 0.7;
}

.emptyBank:nth-child(3) {
    opacity: 0.4;
}

.emptyBank:nth-child(4) {
    opacity: 0.2;
}


.sliderContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.slider {
    display: flex;
    flex-direction: column;
    border: 1px solid #A0A0A0;
    border-radius: 16px;
    padding: 16px;
    gap: 16px;
    background: #F6F6FA;
}

.sliderTop {
    display: flex;
    gap: 8px;
}

.sliderNumber {
    background: linear-gradient(222.4deg, #FF7700 6.42%, #E10CFF 93.77%);
    min-width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    position: relative;
    z-index: 1;
}

.sliderNumber::before {
    content: '';
    position: absolute;
    border-radius: 50px;
    width: 26px;
    height: 26px;
    background: #fff;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.gradient {
    position: relative;
    background: linear-gradient(222.4deg, #FF7700 6.42%, #E10CFF 93.77%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 3;
}

.paymentAssistent {
    background: #1d1347;
    border: 1px solid #A0A0A0;
    padding: 12px;
    border-radius: 16px;
}

.contentAss {
    color: #fff;
}

.contentAssBanks {
    display: flex;
    flex-direction: row;
    gap: 7px;
    max-width: 100%;
    overflow: hidden;
}

.contentAss h3 {
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 12px;
}

.contentAss button {
    margin-top: 10px;
    background: linear-gradient(222.4deg, #FF7700 6.42%, #E10CFF 93.77%);
    border: none;
    outline: none;
    color: #FFFFFF;
    display: flex;
    padding: 7px 14px;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}

.gapInputs {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.fakeInput {
    background: linear-gradient(222.4deg, #FF7700 6.42%, #E10CFF 93.77%);
    border-radius: 16px;
    padding: 8px 12px;
    position: relative;
    max-height: 27px;
    z-index: 1;
}

.fakeInput::before {
    content: '';
    position: absolute;
    background: #fff;
    z-index: 2;
    width: calc(100% - 3px);
    height: calc(100% - 2px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
}

.fakeInput p {
    font-size: 10px;
    color: black;
    position: relative;
    z-index: 3;
}

.fakeInputNull {
    width: 100%;
    height: 27px;
    border-radius: 16px;
    background: #C5C5C880;
}

.fakeInputChoose {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.fakeInputChoose .country {
    display: flex;
    gap: 10px;
    align-items: center;
    background: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #A0A0A0;
    color: #757575;
    padding: 7px 12px;
}

.fakeInputChoose .countryFull {
    display: flex;
    gap: 15px;
    width: 100%;
    align-items: center;
    background: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #A0A0A0;
    color: #757575;
    padding: 7px 12px;
}

.fakeInputChoose .find {
    width: 100%;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #A0A0A0;
    color: #757575;
    padding: 7px 12px;
}

.fakeInputChoose {
    font-family: Manrope;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.fakeInputChooseNull {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.fakeChooseRoundNull {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #A0A0A099;
}

.fakeChooseNull {
    width: 100%;
    height: 10px;
    border-radius: 16px;
    background: #A0A0A099;
}

.sliderNav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.sliderNavButton {
    height: 3px;
    background: none;
    border: 1px solid #A0A0A0;
    border-radius: 10px;
    width: 22px;
    overflow: hidden;
    position: relative;
    transition: 0.5s linear;
}

.active {
    border-radius: 10px;
}

.sliderNavButton::before {
    content: '';
    position: absolute;
    background: linear-gradient(222.4deg, #FF7700 6.42%, #E10CFF 93.77%);
    height: 4px;
    width: 23px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s linear;
}


.sliderNavButton.before-active::before {
    transform: translate(-50%, -50%) translateX(30px);
}

.sliderNavButton.after-active::before {
    transform: translate(-50%, -50%) translateX(-30px);
}

.sliderNavButton.active::before {
    transform: translate(-50%, -50%);
    border-radius: 10px;
}