.payment {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    margin-top: 30px;
}

.payment .ready {
    margin-top: 10px;
    margin-bottom: 20px;
    background: linear-gradient(222.4deg, #FF7700 6.42%, #E10CFF 93.77%);
    border: none;
    outline: none;
    color: #FFFFFF;
    display: flex;
    padding: 14px;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
}

.payment .ready.Ecom {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    
}

.payment .paymentHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    gap: 20px;
    /* margin-top: 30px; */
}

.payment h1 {
    margin: 0;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #353535;
}

.payment .timer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 20px;
    font-weight: 500;
    color: #A8A8A8;
}

.paymentMain {
    display: flex;
    margin: 0 auto;
    gap: 50px;
}

.paymentLeft {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.paymentRight {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.paymentButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

}

.paymentButtons button {
    background: none;
    border: none;
    padding: 16px;
    border-radius: 20px;
    box-shadow: 0px 4px 12px 0px #00000040;
    font-weight: 500;
    font-size: 20px;
    width: 100%;
}

.paymentButtons .sberPay {
    background: linear-gradient(150deg, rgb(242, 234, 0) 0%, rgb(3, 211, 29) 35%, rgb(15, 182, 254) 100%);
    color: #F3F2F8;
    transition: all 150ms ease-in-out;
}

.paymentButtons .sberPay:hover {
    background: linear-gradient(150deg, rgba(255, 249, 41, 1) 0%, rgba(91, 228, 108, 1) 35%, rgba(0, 114, 255, 1) 100%);
    box-shadow: 0px 4px 12px 0px #00000040 inset;
}

.paymentButtons .paymentDone {
    color: #F3F2F8;
    background: #0088FF;
    transition: all 150ms ease-in-out;
}

.paymentButtons .paymentDone:hover {
    background: #026BC8;
    box-shadow: 0px 4px 12px 0px #00000040 inset;
}

.paymentBlur {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(3px);
    z-index: 1000;
    transition: all 150ms ease-in-out;
}

.paymentLoading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.paymentButtons .tPay {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    color: #303030;
    background: #FFDD2D;
    padding: 10px;
}

@media screen and (max-width: 1000px) {
    .payment {
        margin-top: 15px;
        gap: 16px;
        max-width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
    }

    .payment h1 {
        font-size: 24px;
    }

    .paymentButtons {
        flex-direction: column;
        gap: 15px;
        margin-left: 24px;
        margin-right: 24px;
    }

    .paymentButtons button {
        flex-grow: 1;
        font-size: 16px;
    }

    .paymentButtons .tPay {
        padding: 8px;
    }

}

.Desktop {
    background: #FFFFFF;
    border-radius: 30px;
    box-shadow: 0px 4px 12px 0px #00000014;
    max-width: 550px;
    padding: 40px;
    margin: 0 auto;
    justify-content: center;
    flex-direction: column;
    display: flex;
    gap: 35px;
}

.Desktop h1 {
    text-align: center;
}

.Desktop button {
    width: 100%;
    background: linear-gradient(222.4deg, #FF7700 6.42%, #E10CFF 93.77%);
    border-radius: 16px;
    padding: 12px 20px;
    color: #FFFFFF;
    font-size: 32px;
    border: none;
    outline: none;
}

@media screen and (max-width: 400px) {
    .payment {
        margin-top: 5px;
        gap: 5px;
    }

    .payment h1 {
        font-size: 20px;
    }

    .paymentButtons {
        flex-direction: column;
        gap: 24px;
        margin-left: 12px;
        margin-right: 12px;
    }

    .paymentButtons button {
        flex-grow: 1;
        font-size: 16px;
    }

    .paymentButtons .tPay {
        padding: 8px;
    }

}