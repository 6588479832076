.waiting {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap: 16px;
    max-width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
}

.waitingText {
    background: #FFFFFF;
    border-radius: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    box-shadow: 0px 4px 12px 0px #00000014;
    padding: 24px;
    box-sizing: border-box;
}

.waitingText h2 {
    padding: 0;
    margin: 0;
    font-size: 24px;
    font-weight: 600;
}

.waitingText p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: #757575;
}

.waitingLoop {
    display: flex;
    justify-content: center;
}

.waitingLoop svg {
    animation: rotateLoop 2s linear infinite;
}

.waitingTimer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.waitingTimer p {
    margin: 0;
    font-size: 32px;
    font-weight: 500;
    color: #353535;
}

.waitingNo {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.waitingNo p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #A8A8A8;
    text-decoration: underline;
}

@keyframes rotateLoop {
    0% {
        transform: rotate(0deg);
        transform-origin: center;
    }

    100% {
        transform: rotate(360deg);
        transform-origin: center;
    }
}

/* @media screen and (max-width: 1000px) {
    .waiting {
        margin-left: 24px;
        margin-right: 24px;
    }

    .waitingText {
        gap: 25px;
        margin: 30px 35px 0 35px;
    }

    .waitingText h2 {
        font-size: 24px;
    }

    .waitingText p {
        font-size: 16px;
    }

    .waitingTimer svg {
        width: 18px;
        height: 18px;
    }

    .waitingTimer p {
        font-size: 20px;
        color: #A8A8A8;
    }

    .waitingNo p {
        font-size: 14px;
    }

} */