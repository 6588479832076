.chat {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto;
    margin-top: 15px;
}

.chatHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-top: 16px;
    background: linear-gradient(90deg, #0088FF 0%, #F03FFD 100%);
    color: #F3F2F8;
    padding: 20px;
    border-radius: 20px 20px 0 0;
}

.chatHeader h4 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}

.chatSupport {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.chatSupport .chatStatus {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.chatStatus span {
    font-size: 14px;
    font-weight: 400;
}

.chatMessages {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 350px;
    background: #FFFFFF;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: flex-end;
}

.chatMessages::-webkit-scrollbar {
    display: none;
}

.chatMessageBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.chatMessageBlock p {
    max-width: 350px;
    margin: 0;
    padding: 16px;
    border-radius: 10px;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: anywhere;
}

.chatMessageBlock span {
    font-size: 13px;
    font-weight: 400;
    color: #737376;
}

.chatMessageBlock.user {
    justify-content: flex-end;
    margin-right: 20px;
}

.chatMessageBlock.support {
    justify-content: flex-start;
    margin-left: 20px;
}

.chatMessage {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.chatFile {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.chatFileName {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    background: #F2F2F2;
    color: #353535;
    padding: 16px;
    border-radius: 10px;
    cursor: pointer;
}

.chatMessageBlock.user p {
    background: linear-gradient(90deg, #0088FF 0%, #F03FFD 100%);
    color: #F3F2F8;
}

.chatMessageBlock.user span {
    text-align: right;
}

.chatMessageBlock.support p {
    background: #F2F2F2;
    color: #353535;
}

.chatMessageBlock.support span {
    text-align: left;
}

.chatLogo .logoIco {
    width: 32px;
    height: 32px;
    transform: translate(0, -10px);
}

.logoIco {
    border-radius: 50%;
}

.chatSend {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    border-top: 1px solid #F2F2F2;
    background: #FFFFFF;
    width: 100%;
    border-radius: 0px 0px 20px 20px;
}

.chatSend input {
    width: -webkit-fill-available;
    border: none;
    border-radius: 0px 0px 20px 20px;
    background: none;
    padding: 20px;
    font-size: 15px;
    font-weight: 400;
}

.chatSend input[type='file'] {
    visibility: hidden;
    position: absolute; 
    left: -9999px;
}

.chatSend label:hover svg defs linearGradient stop:first-child{
    stop-color: #9BCCF9;
}

.chatSend label:hover svg defs linearGradient stop:last-child{
    stop-color: #0088FF;
}

.chatSend svg {
    padding-right: 20px;
}

@media screen and (max-width: 1000px) {

    .chat {
        margin-left: 24px;
        margin-right: 24px;
    }

    /* .chatMessages {
        max-height:  400px;
    } */

    .chatMessageBlock p {
        max-width: 220px;
    }

}