.quickPay {
    background: #FFFFFF;
    border-radius: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    box-shadow: 0px 4px 12px 0px #00000014;
    padding: 24px;
    box-sizing: border-box;
}

.quickPay h2 {
    padding: 0;
    margin: 0;
    font-size: 24px;
    font-weight: 600;
}

.quickPay p {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: #757575;
}

.quickPayHeader {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.quickPayButtons {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.quickPay button {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    padding: 14px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    outline: none;
    border-radius: 16px;
}

.quickPay .tPay {
    color: #302A0B;
    background: #FFDD2D;
}

.quickPay .tPay:hover {
    background: #FFE55D;
}

.quickPay .sberPay {
    color: #FFFFFF;
    /* background: linear-gradient(90deg, #3FE001 0%, #00D7BE 100%); */
    background: #4bc919;
    padding: 10.5px;
}

.quickPay .SBP {
    color: #FFFFFF;
    background: #1D1347;
}

.quickPay .SBP:hover {
    background: #6553B1;
}

.quickPay .warning {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    padding: 0px 30px;
}

.quickPay .payContainer {
    padding: 0;
}

.quickPay .payContainer p {
    color: #2B2B2A;

}

@media screen and (max-width: 400px) {
    .quickPay {
        padding: 18px;
    }

    .quickPay p {
        font-size: 14px;
    }

    .quickPay button {
        font-size: 16px;
    }

    .bank {
        gap: 8px;
        padding: 8px;
    }

    .choose {
        gap: 4px;
    }

}